import { NZ_I18N } from 'ng-zorro-antd/i18n';
import pl from '@angular/common/locales/pl';
import {DEFAULT_CURRENCY_CODE, ErrorHandler, LOCALE_ID} from '@angular/core';
import { registerLocaleData } from '@angular/common';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { HttpClientInMemoryWebApiModule } from 'angular-in-memory-web-api';
import { ClipboardModule } from 'ngx-clipboard';
import { TranslateModule } from '@ngx-translate/core';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthService } from './modules/auth/services/auth.service';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { DateFnsModule } from 'ngx-date-fns';

import { pl_PL } from 'ng-zorro-antd/i18n';

import { DateFnsConfigurationService } from 'ngx-date-fns';
import { pl as polishDateFns } from 'date-fns/locale';
import * as Sentry from "@sentry/angular";

const polishConfig = new DateFnsConfigurationService();
polishConfig.setLocale(polishDateFns);

import localePl from '@angular/common/locales/pl';
import {Router} from "@angular/router";
import {platformBrowserDynamic} from "@angular/platform-browser-dynamic";
import { environment } from '../environments/environment';

Sentry.init({
  dsn: "https://54d7abc12fd1e5dbfef7ec1eaab04678@sentry.codewell.pl/5",
  environment: environment.envName,
  integrations: [
    // Registers and configures the Tracing integration,
    // which automatically instruments your application to monitor its
    // performance, including custom Angular routing instrumentation
    Sentry.browserTracingIntegration(),
    // Registers the Replay integration,
    // which automatically captures Session Replays
    Sentry.replayIntegration(),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for tracing.
  // We recommend adjusting this value in production
  // Learn more at
  // https://docs.sentry.io/platforms/javascript/configuration/options/#traces-sample-rate
  tracesSampleRate: 1.0,

  // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
  tracePropagationTargets: [
    /^localhost(:\d+)?$/, // Lokalny host na dowolnym porcie (np. localhost:4200)
    /^https:\/\/api\.kontrakton\.pl$/, // Prod
    /^https:\/\/api-uat\.kontrakton\.pl:8443$/ // Uat
  ],

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  // Learn more at
  // https://docs.sentry.io/platforms/javascript/session-replay/configuration/#general-integration-configuration
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

registerLocaleData(localePl, 'pl', {
  currency: 'PLN',
  currencySymbol: 'pkt.'
});

function appInitializer(authService: AuthService) {
  return () => {
    return new Promise((resolve) => {
      //@ts-ignore
      authService.getUserByToken().subscribe().add(resolve);
    });
  };
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    TranslateModule.forRoot(),
    HttpClientModule,
    ClipboardModule,
    AppRoutingModule,
    InlineSVGModule.forRoot(),
    NgbModule,
    SweetAlert2Module.forRoot(),
    DateFnsModule.forRoot(),
    NgxMaskDirective,
    NgxMaskPipe
  ],
  providers: [
    Title,
    {
      provide: LOCALE_ID,
      useValue: "pl"
    },
    {
      provide: 'PLN',
      useValue: 'pkt.'
    },
    {
      provide: NZ_I18N,
      useValue: pl_PL
    },
    {
      provide: DateFnsConfigurationService,
      useValue: polishConfig
    },
    {
      provide: DEFAULT_CURRENCY_CODE,
      useValue: 'PLN'
    },
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializer,
      multi: true,
      deps: [AuthService],
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler(),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    provideNgxMask(),
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }

platformBrowserDynamic().bootstrapModule(AppModule).catch((err) => console.error(err));
